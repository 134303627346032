import api from '../../utils/api-axios';
import axios from 'axios';
import store from '../../store';
import toFormData from '../../utils/to-form-data';

async function saveToBackend(stories) {
  /* eslint-disable-next-line no-console,no-unreachable */
  console.log('Story importer: Saving to backend...');
  /* eslint-disable-next-line no-console */
  console.log('Story importer: Saving countries to backend...');
  const countries = await insertLookups(
    stories,
    'country',
    '/stories-service/countries',
  );
  /* eslint-disable-next-line no-console */
  console.log('Story importer: Saving categories to backend...');
  const categories = await insertLookups(
    stories,
    'categories',
    '/stories-service/categories',
    true,
  );
  /* eslint-disable-next-line no-console */
  console.log('Story importer: Saving series to backend...');
  const series = await insertLookups(
    stories,
    'series',
    '/stories-service/series',
  );
  const lookupsWithId = { countries, categories, series };

  const existingStories = (
    await api.get('/stories-service/feed?limit=999999999999')
  ).data;

  const existingStoriesStoryNumber = existingStories.map(
    story => story.storyNumber,
  );

  stories = stories.map(story => fixStory(story, lookupsWithId));

  stories = stories.filter(
    story => !existingStoriesStoryNumber.includes(story.storyNumber),
  );

  /* eslint-disable-next-line no-console */
  console.log('Story importer: Saving stories to backend...');
  const promises = [];
  for (let story of stories) {
    if (story.image) {
      const response = await axios.get(
        story.image.large.replace('_desktopimages/', ''),
        {
          responseType: 'arraybuffer',
        },
      );
      story.imageFile = new File([response.data], 'filename', {
        type: 'image/png',
      });

      delete story.image;
    }

    const promise = api.post('/stories-service/stories', toFormData(story));

    store.dispatch('setLoaderSnackbar', {
      promise,
      text: `Importing story ${story.storyNumber} ${story.name}...`,
      successText: `Done importing story ${story.storyNumber} ${story.name}.`,
      successTimeout: 1000,
    });

    promise.then(() => promises.splice(promises.indexOf(promise), 1));
    promises.push(promise);

    await waitWhileArrayIsTooLong(promises, 5);
  }
  /* eslint-disable-next-line no-console */
  console.log('Story importer: Done.');
}

// eslint-disable-next-line no-unused-vars
async function waitWhileArrayIsTooLong(arr, maxLength) {
  do {
    await wait(200);
  } while (arr.length >= maxLength);
}

async function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function insertLookups(
  stories,
  lookupPropertyName,
  lookupEndpoint,
  arrayProperty,
) {
  let lookups = stories.map(story => story[lookupPropertyName]);
  if (arrayProperty) {
    lookups = lookups.reduce((acc, curr) => {
      acc.push(...curr);
      return acc;
    }, []);
  }

  const existingLookups = (await api.get(lookupEndpoint)).data;
  const existingLookupValues = existingLookups.map(lookup => lookup.value);
  const newLookups = [];

  for (let lookup of lookups) {
    if (
      !existingLookupValues.includes(lookup) &&
      !newLookups.includes(lookup)
    ) {
      newLookups.push(lookup);
    }
  }

  for (let lookup of newLookups) {
    const promise = api.post(lookupEndpoint, { value: lookup });
    store.dispatch('setLoaderSnackbar', {
      promise,
      text: `Importing lookup ${lookup}...`,
      successText: `Done importing lookup ${lookup}.`,
    });
    await promise;
  }

  return (await api.get(lookupEndpoint)).data;
}

function fixStory(story, lookups) {
  const { countries, series, categories } = lookups;

  story.country = countries.filter(
    country => country.value === story.country,
  )[0];
  story.series = series.filter(series => series.value === story.series)[0];
  story.categories = categories.filter(category =>
    story.categories.includes(category.value),
  );
  story.subcategories = [];
  story.authorEmail = store.state.user.email;
  story.storyNumber = parseInt(story.storyNumber);
  story.priority = 0;
  story.publishDate = new Date();
  story.isEnabled = true;
  story.image = {
    large: story.image.large || '',
    medium: story.image.medium || '',
    placeholder: story.image.placeholder || '',
  };

  return story;
}

export default saveToBackend;
