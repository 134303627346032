export default `#title
title->storyNumber
storyNameOrAlias->name
storyCountry.label->country
storyCategories->categories
storyType.label->series
storyVimeoUrlShortStory->vimeoUrl.preview
storyVimeoUrlFullStory->vimeoUrl.full
storyDescription->description
storyImageDesktop->image.original
storyImageDesktop->image.large
storyImageMobile->image.medium
storyImagePlaceholder->image.placeholder
`;
