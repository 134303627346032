<template>
  <v-container :class="$style.self">
    <v-container>
      <v-card>
        <v-row align="center" justify="center"
          ><h2 class="my-5">Import data...</h2></v-row
        >
        <v-switch
          v-model="textInput"
          :label="textInput ? 'Text' : 'URL'"
          class="px-5"
        ></v-switch>
        <v-textarea
          v-if="textInput"
          class="px-5"
          @input="d => (initialData = JSON.parse(d))"
        />
        <StoryFetcher v-else @fetched="d => (initialData = d)" />
      </v-card>
    </v-container>
    <v-container v-if="!textInput">
      <v-card height="200px" style="overflow-y: scroll;">
        {{ initialData }}
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <StoryFormatTranslator
          :input="initialData"
          @modified="d => (modifiedData = d)"
        />
      </v-card>
    </v-container>
    <v-container>
      Output:
      <v-card height="200px" style="overflow-y: scroll;">
        {{ modifiedData }}
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-row justify="center" class="py-5">
          <v-btn @click="saveToBackend">Import</v-btn>
        </v-row>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import StoryFetcher from './StoryFetcher';
import StoryFormatTranslator from './StoryFormatTranslator';
import saveToBackend from './saveToBackend';

export default {
  components: {
    StoryFormatTranslator,
    StoryFetcher,
  },
  data() {
    return {
      textInput: true,
      initialData: [],
      modifiedData: [],
    };
  },
  methods: {
    saveToBackend() {
      saveToBackend(this.modifiedData);
    },
  },
};
</script>

<style lang="scss" module>
.self {
}
</style>
