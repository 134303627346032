<template>
  <div :class="$style.self">
    <v-container>
      <v-row
        ><v-col>URL: <v-text-field v-model="url"></v-text-field></v-col
      ></v-row>
      <v-row
        ><v-col
          >Property path:
          <v-text-field v-model="propertyPath"></v-text-field></v-col
      ></v-row>
      <v-row align="center">
        <v-col><v-btn @click="fetchData">Fetch</v-btn></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import getDeepProperty from './get-deep-property';

export default {
  data() {
    return {
      url: 'https://cms.thehumanaspect.com/stories.json',
      propertyPath: '',
      fetchedData: null,
    };
  },
  watch: {
    propertyPath() {
      this.loadData();
    },
    fetchedData() {
      this.loadData();
    },
  },
  methods: {
    fetchData() {
      axios.get(this.url).then(result => (this.fetchedData = result.data));
    },
    loadData() {
      if (!this.fetchedData) return;
      this.$emit(
        'fetched',
        getDeepProperty(this.fetchedData, this.propertyPath),
      );
    },
  },
};
</script>

<style lang="scss" module>
.self {
}
</style>
