<template>
  <div :class="$style.self" class="px-5">
    <v-textarea v-model="modifier"></v-textarea>
    <p class="pb-5 caption">
      The modifier should be on the form: <br />
      #number-sortable-identifier <br />
      propA->newPropA <br />
      propB->propC.newPropB
    </p>
  </div>
</template>

<script>
import getDeepProperty from './get-deep-property';
import setDeepProperty from './set-deep-property';
import defaultModifier from './default-modifier';

export default {
  props: {
    input: {
      type: Array,
      default: null,
      modifyDelayTimeout: null,
    },
  },
  data() {
    return {
      modifier: defaultModifier,
    };
  },
  watch: {
    input() {
      this.modifyStories();
    },
    modifier() {
      this.delayedModifyStories();
    },
  },
  mounted() {
    this.modifyStories();
  },
  methods: {
    delayedModifyStories() {
      if (this.modifyDelayTimeout) clearTimeout(this.modifyDelayTimeout);
      this.modifyDelayTimeout = setTimeout(() => this.modifyStories(), 2000);
    },
    modifyStories() {
      if (!this.modifier) {
        this.$emit('modified', this.input);
        return;
      }
      let m = this.modifier;

      let stories = [...this.input];

      const index = (m.match(/(?<=#)[\w.]+/) || [])[0];
      m = m.replace(/#[\w.]+\s+/, '');

      const mappers = m
        .trim()
        .split('\n')
        .map(m => m.split('->'));

      if (index) {
        stories.sort((a, b) => parseInt(a[index]) - parseInt(b[index]));
      }

      const modifiedStories = [];
      stories.forEach(story => {
        const modifiedStory = {};

        mappers.forEach(([from, to]) => {
          const value = getDeepProperty(story, from);
          setDeepProperty(modifiedStory, to, value);
        });

        modifiedStories.push(modifiedStory);
      });

      this.$emit('modified', modifiedStories);
    },
  },
};
</script>

<style lang="scss" module>
.self {
}
</style>
